
import React from 'react';
import { Button, Row } from 'antd';

export interface CustomerToolBarProps {
  style?: any;
  action?: (actionName: string) => void;
}

export default class CustomerToolBar extends React.Component<CustomerToolBarProps, any> {

  buttonClickHandler = (name: string) => { 
    this.props.action && this.props.action(name);
  }

  render(): React.ReactNode {
    return (
      <Row style={this.props.style} className="table-tool-bar">
        
<Button type="primary" onClick={() => { this.buttonClickHandler('add') }}  style={ { marginLeft: 0 } }>新增</Button>

<Button type="ghost" onClick={() => { this.buttonClickHandler('export') }}  style={ { marginLeft: 12 } }>导出</Button>

      </Row>
    );
  }
}
