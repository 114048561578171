
import DataTableList from '@system/data-table-list';
import CustomerSearchForm from './components/search-form';
import CustomerToolBar from './components/toolbar';
import React from 'react';
import request from '@system/utils/http';
import { Drawer, message } from 'antd';
import CustomerDetail from '../detail';


export default class CustomerTableList extends React.Component<any, any> { 

  // 表单数据
  formData: any;

  constructor(props: any) {
    super(props);
    this.formData = {
      page: 0,
      pageSize: 20
    };
    this.state = {
      dataList: [],
      drawerVisible: false
    };
  }

  componentDidMount() { 
    this.formSearchHandler();
  }

  // 搜索处理
  formSearchHandler = (formData?: any) => { 
    // 此处进行数据接口调用，刷新列表
    this.formData = Object.assign({ }, this.formData, formData);

    this.searchPageHandler(this.formData.page, this.formData.pageSize);
  };

  // 搜索页码
  searchPageHandler = (page: number, pageSize: number) => { 
    this.formData.page = page;
    this.formData.pageSize = pageSize;

    request.get("https://tinyapi.chenyunjie.com/api/customer/list", this.formData).then(data => {
      if (data.status === 1) {
        this.setState({ dataList: data.data });
      } else {
        message.error(data.message);
      }
    });
  }

  // 工具栏回调
  toolBarActionHandler = (action: string) => { 

  }
  

  render(): React.ReactNode {
    const columns = this.buildColumns();
  
    return (
      <>
        {/* 搜索条件表单 */}
        <CustomerSearchForm onSearch={ this.formSearchHandler } />
        
        { /* 操作工具条 */ }
        <CustomerToolBar style={ { marginTop: 10 } } action={ this.toolBarActionHandler } />

        { /* 数据列表 */ }
        <DataTableList onTableItemClick={this.toggleDrawer} dataList={this.state.dataList} columns={columns} onRequestPage={this.formSearchHandler} style={{ marginTop: 12, marginLeft: 12 }} />
        
        <Drawer width={640} placement="right" closable={false} onClose={this.toggleDrawer} visible={this.state.drawerVisible}>
          <CustomerDetail queryForm={{}}/>
        </Drawer>
      </>
      
    );
  }

  buildColumns = () => { 
    return [{"title":"姓名","dataIndex":"name","link":true},{"title":"年龄","dataIndex":"age"},{"title":"住址","dataIndex":"address"}]
  }

  toggleDrawer = () => { 
    this.setState({ drawerVisible: !this.state.drawerVisible });
  }
}
