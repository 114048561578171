import http from "@system/utils/http"

/**
 * 用户名密码登录
 * @param username 
 * @param password 
 */
function loginWithPassword(username: string, password: string) {
  return http.post('/console/commons/auth/signin/username', {
    username,
    password
  });
}

export {
  loginWithPassword
}