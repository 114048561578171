import { Form, Input } from "antd";
import React from "react";

export interface FormItemInputProps {
  formKey: string;
  value?: any;
  placeholder?: string;
}

export default class FormItemInput extends React.Component<FormItemInputProps, any> {

  render() {
    const { formKey, value, ...other } = this.props;
    return (
      <Form.Item name={formKey} style={ {marginBottom: 12} }>
        <Input {...other} value={ value } />
      </Form.Item>
    );
  }
}