import React, { Key } from 'react';
import TableOperationButton from './table-operation-button';
import { Table } from 'antd';

/**
 * 列配置
 */
interface Column {
  title: string;
  operations?: Array<TableOperationButton>;
  dataIndex: string;
  // 是否为可点击链接
  link?: boolean;
  
}

interface DataTableListProps  {

  onRequestPage?: (pageInfo: any) => void;
  columns: Array<Column>;

  // 是否支持分页
  pageEnabled?: boolean;

  style?: any;

  dataList: Array<any>;

  onTableItemClick?: (item: any) => void;

  // 是否多选
  mutiple?: boolean;

  // 多选了
  onSelectedRows?: (rows: Array<any>) => void;
}

interface DataTableListState {
  
}

/**
 * 通用版本列表
 * 
 * props: {
 *  onRequestPage
 *  columns: Array<Column>
 * }
 */
export default class DataTableList extends React.Component<DataTableListProps, DataTableListState> {

  state: DataTableListState;

  constructor(props: DataTableListProps) {
    super(props);
    this.state = {
      dataList: []
    };
  }

  render(): React.ReactNode {

    const { onTableItemClick = () => { }, mutiple=false } = this.props;

    const columns: any = this.props.columns.map((col: Column) => { 
      const tableColumn: any = {
        key: col.dataIndex,
        title: col.title,
        dataIndex: col.dataIndex
      };
      if (col.link === true) {
        tableColumn.render = (_: any, row: any) => { 
          return <a onClick={ () => onTableItemClick(row) } style={{color: '#4386f5'}} href="#">{ row[col.dataIndex]}</a>
        };
      }
      return tableColumn;
    });

    let otherProps: any = {};

    if (mutiple === true) {
      otherProps.rowSelection = this.rowSelection();
    }

    return (
      <>
        <Table {...otherProps} columns={columns} dataSource={this.props.dataList} style={ this.props.style } />
      </>
    );
  }

  rowSelection = () => { 
    return {
      onChange: (selectKeys: Array<Key>, selectRows: any) => { 
        const { onSelectedRows } = this.props;
        if (onSelectedRows) {
          onSelectedRows(selectKeys);
        }
      }
    };
  }
}