import InfoContent from "@system/components/info/info-content";
import InfoSection from "@system/components/info/info-section";
import http from "@system/utils/http";
import { useEffect, useState } from "react";

interface CustomerDetailProps {
  queryForm: any;
}

const CustomerDetail = (props: CustomerDetailProps) => { 

  const [customer, setCustomer] = useState({});

  useEffect(() => { 
    http.get("https://tinyapi.chenyunjie.com/api/customer/get?name=%E9%99%88%E4%BA%91%E6%9D%B0", props.queryForm).then(response => {
      setCustomer(response.data);     
    });
  }, [props.queryForm]);

  return (
    <>
      <InfoSection title='客户信息' />

      <InfoContent
        data={ customer }
        displayFields={[{ label: "姓名", field: 'name' }, { label: "性别", field: 'sex' }, { label: "生日", field: 'birthday' }, { label: "地址", field: 'address' }]}
        cols={1}
      />
    </>
  );
}

export default CustomerDetail