import './menu-item.less';

import { MenuItemVO } from "./typing";
import Icon from '@system/components/icon/index';
import theme from 'src/style/theme';

export type MenuItemClick = (item: MenuItemVO) => void;

export interface MenuItemProps {
  
  item: MenuItemVO;

  selected?: boolean;

  onClick?: MenuItemClick;

  roundRightBottom?: boolean;

  roundRightTop?: boolean;
}

// 菜单项目
const MenuItem = (props: MenuItemProps) => {
  
  const { onClick = (_: MenuItemVO) => { } } = props;

  return (
    <div className={"b2b-menu-item-wrapper"}>
      <div
        className={"b2b-menu-item" + (props.selected ? ' b2b-menu-item-selected' : '')}
        onClick={(e) => {
          e.stopPropagation();
          onClick(props.item);
        }}>
        
        <div className='b2b-menu-item-content'>
          <Icon name={props.item.icon || ''} size={16} color={ props.selected ? theme.b2bPrimaryColor : '#666666' } />
          <span className='b2b-menu-item-text'>{ props.item.name }</span>
        </div>
      
      </div>
    </div>
    
  );
}

export default MenuItem;