import { useEffect, useState } from "react";
import system from "src/services/system";
import Sider from "antd/lib/layout/Sider";
import './menu.less'
import event from '@system/utils/event';

import MainMenu from "./main-menu";
import { MenuItemVO } from "./typing";

const SideMenu = (props: any) => { 

  const [collapsed, setCollapsed] = useState(false);

  const [menus, setMenus] = useState([]);

  const [mainMenu, setMainMenu] = useState({} as any);

  const menuChangeHandler = (menu: MenuItemVO) => { 
    menuChange(menus, menu.url || '');
  }

  const queryMenus = () => { 
    system.fetchMenus().then((response) => { 
      menuChange(response, locationPath());
      response = response.splice(response.length - 1, 1).concat(response);
      setMenus(response);
    });
  }

  const menuChange = (allMenus: Array<MenuItemVO>, path: string) => { 
    
    if (path === '') {
      path = '/';
    }

    let breadMenus = new Array<MenuItemVO>();
    allMenus.forEach((menu: MenuItemVO) => {

      if (path === menu.url && (!menu.children || menu.children?.length === 0)) {
        breadMenus.push(menu);
      }

      if (menu.children && menu.children.length > 0) {
        menu.children.forEach((sub: MenuItemVO) => {
          if (path === sub.url && (!sub.children || sub.children?.length === 0)) {
            breadMenus.push(menu);
          }

          if (sub.children && sub.children.length > 0) {
            sub.children.forEach((subsub: MenuItemVO) => { 
              if (path === subsub.url) {
                breadMenus = [menu, sub, subsub];
              }
            });
          }
        })
      }
    });

    if (breadMenus) {
      props.onMenuChange && props.onMenuChange(breadMenus);

      if (breadMenus.length > 0) {
        setMainMenu(breadMenus[0]);
      }
    }
  }

  useEffect(() => {
    queryMenus();
    event.on('menu:refresh', queryMenus);

    return () => { 
      event.off('menu:refresh', queryMenus);
    };
  });

  return (
    <>
      <Sider
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        width={120} collapsedWidth={50}
        style={{ overflow: 'hidden', background: '#f0f2f5' }}>
        <div className="logo" />
        <MainMenu items={menus} onChange={menuChangeHandler} selectedMenu={ mainMenu } />
      </Sider>
    </>
    
  );
}


function locationPath() {
  const href = document.location.href;
  if (href.indexOf('#') >= 0) {
    return href.split('#')[1];
  }
  return "";
}

export default SideMenu;