import React from 'react';

const Summary = () => { 
  return (
    <>
      <div>这是概览页面</div>
    </>
  );
}

export default Summary;