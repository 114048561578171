import CustomerTableList from './pages/customer/list/index';
import Summary from './pages/summary';

import CustomerEdit from './pages/customer/edit/index';

const routers = [
  {
    path: "/",
    component: Summary
  },
  {
    path: "customer/list",
    component: CustomerTableList
  },
  {
    path: "customer/add",
    component: CustomerEdit
  }
];

export default routers;