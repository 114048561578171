
import { MenuItemVO } from "./typing";
import './main-menu.less';
import MenuItem from "./menu-item";
import { useState } from "react";
import { Divider, Popover } from "antd";
import { useNavigate } from "react-router-dom";

// 菜单选择变更
export type MenuChangeAction = (newMenu: MenuItemVO, oldMenu?: MenuItemVO, breadMenus?: Array<MenuItemVO>) => void;
export type MenuClickAction = (menu: MenuItemVO) => void;

export interface MainMenuProps {

  items: Array<MenuItemVO>;

  onChange?: MenuChangeAction;

  selectedMenu?: MenuItemVO | null
}

// 主菜单
const MainMenu = (props: MainMenuProps) => { 

  const selectedItem = props.selectedMenu;

  const [selectedSubItem, setSelectedSubItem] = useState({} as MenuItemVO | null);

  const [currentClickMenu, setCurrentClickMenu] = useState({} as MenuItemVO | null);

  const [subMenuVisible, setSubMenuVisible] = useState(false);

  const itemClickHandler = (item: MenuItemVO) => { 

    setCurrentClickMenu(item);

    const isPage = item.type === 'PAGE';

    // 一级菜单为页面
    if (isPage) {
      setSubMenuVisible(false);
      setSelectedSubItem(null);
      item.url && navigate(item.url);
      const { onChange = null } = props;

      if (onChange && item && item.id !== selectedItem?.id) {
        onChange(item);
      }

    } else {
      setSubMenuVisible(true);
    }
  }

  const subMenuVisibleChange = (visible: boolean) => {
    if (visible === false) {
      setSubMenuVisible(visible);
      setCurrentClickMenu(null);
    }
  }

  
  const navigate = useNavigate();
  const subMenuSelectHandler = (menu: MenuItemVO) => { 
    setSelectedSubItem(menu);
    setSubMenuVisible(false);
    
    if (menu.url) {
      navigate(menu.url);
    }
    const { onChange = null } = props;
    if (onChange && menu && menu.id !== selectedItem?.id) {
      onChange(menu);
    }
  }

  const menus = props.items.map((menu, index) => { 
    const selected = menu.id === selectedItem?.id;

    const isClicking = currentClickMenu && currentClickMenu.id === menu.id;

    return (
      <Popover
        placement="rightTop"
        visible={subMenuVisible && !!isClicking}
        content={<SubMenuPanel menu={menu} selectedSubMenu={ selectedSubItem || null } onClick={subMenuSelectHandler} />}
        onVisibleChange={subMenuVisibleChange}
        trigger="click">
        <MenuItem
          item={menu}
          key={menu.id}
          selected={selected || !!isClicking}
          onClick={itemClickHandler} />
      </Popover>
      
    );
  });

  return (
    <div className="b2b-main-menu">
      {menus}
    </div>
  );

}

export interface SubMenuPanelProps {
  menu: MenuItemVO;
  selectedSubMenu?: MenuItemVO | null;
  onClick?: MenuClickAction;
}

// 子菜单面板
const SubMenuPanel = (props: SubMenuPanelProps) => { 

  const menus: any = [];

  props.menu.children?.forEach(menu => { 
    menus.push(<SubMenuTitleItem menu={menu} onClick={props.onClick} selectedSubMenu={ props.selectedSubMenu } />)
  });


  return (
    <div className="sub-menu-panel">
      { menus }
    </div>
  );
}

interface SubMenuTitleItemProps {
  menu: MenuItemVO;
  onClick?: MenuClickAction;
  selectedSubMenu?: MenuItemVO | null;
}

const SubMenuTitleItem = (props: SubMenuTitleItemProps) => { 

  const menuClickHandler = (menu: MenuItemVO) => {
    if (props.onClick) {
      props.onClick(menu);
    }
  };

  const items = props.menu.children?.map((m: MenuItemVO) => {
    return <div className={"sub-menu-content-item" + (props.selectedSubMenu?.id === m.id ? ' sub-menu-content-item-selected' : '')} onClick={ menuClickHandler.bind(this, m) }>{ m.name }</div>
  });

  return (
    <div>
      <span className="sub-menu-title-text">{props.menu.name}</span>
      <Divider style={{ marginTop: 12 }} />
      <div className="sub-menu-content">
        { items }
      </div>
    </div>
  );
}


export default MainMenu;