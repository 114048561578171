import { Row } from "antd";


interface InfoItemProps {
  label: string;
  value: string;
}

const InfoItem = (props: InfoItemProps) => { 

  return (
    <Row style={ { paddingBottom: 12, paddingLeft: 24 } }>
      <span className="info-item-label">{props.label}</span>
      <span className="info-item-value">{props.value}</span>
    </Row>
  );
}

export default InfoItem;