import { Route, Routes } from 'react-router-dom';
import routers from '../routers';

const RouteContainer = () => { 

  return (
    <Routes>
      { routers.map((router, i) => <Route path={router.path} element={<router.component />} key={router.path}/>) }
    </Routes>
  );
}

export default RouteContainer;