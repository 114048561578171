import { Breadcrumb, Layout, message } from "antd";
import React, { useEffect, useState } from "react";

import { HashRouter, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/login";
import SideMenu from "./framework/menu/menu";
import RouteContainer from "./framework/route-container";
import FrameHeader from "./framework/header/index";
import { MenuItemVO } from "./framework/menu/typing";

const { Content, Footer } = Layout;

const App: React.FC = () => {
	const [currentMenu, setCurrentMenu] = useState(new Array<MenuItemVO>());

	const onMenuChange = (menus: Array<MenuItemVO>) => {
		setCurrentMenu(menus);
	};

	return (
		<HashRouter>
			<Routes>
				<Route path="/login" element={<Login />} />
			</Routes>

			<Layout
				className="site-layout"
				style={{ backgroundColor: "#ffffff",minHeight: '100vh' }}
			>
				{/* 头部区域 */}
        <FrameHeader />
        
				<Layout>
					{/* 侧边菜单 */}
					<SideMenu onMenuChange={onMenuChange} />
					<Content className="app-content">
						{/* 面包屑 */}
						<Breadcrumb style={{ margin: "16px 0" }}>
							{currentMenu.map((m) => (
								<Breadcrumb.Item>{m.name}</Breadcrumb.Item>
							))}
						</Breadcrumb>

						{/* 路由内容区域 */}
						<RouteContainer />
					</Content>
				</Layout>
				<Footer
					style={{ textAlign: "center", backgroundColor: "#f7f7f7" }}
				>
					©Copyright 2022 钛果信息技术有限公司
				</Footer>
			</Layout>

			<AuthChecker />
		</HashRouter>
	);
};

let hide: any = null;

const AuthChecker = () => {
	const navigate = useNavigate();

	useEffect(() => {
		if (!!hide) {
			return;
		}
		const token = localStorage.getItem("token");
		if (!token && window.location.href.indexOf("/#/login") < 0) {
			localStorage.removeItem("token");
			localStorage.removeItem("tokenHeader");
			hide = message.error("登录超时", 2, () => {
				hide();
				hide = null;
			});
			setTimeout(() => {
				navigate("/login");
			}, 1000);
		}
	});
	return <></>;
};

export default App;
