import { Col, Row } from "antd";
import InfoItem from "../info-item";

interface InfoContentProps {
  // 列数， 默认值： 1列
  cols: number;

  // 数据对象
  data: any;

  // 展示数据字段
  displayFields: Array<{label: string, field: string}>;
}

const gutter = 24;

const InfoContent = (props: InfoContentProps) => { 

  let { cols, displayFields, data = {} } = props;

  if (!cols) {
    cols = 1;
  }

  if (!displayFields || displayFields.length === 0) {
    return null;
  }

  // 总行数
  const rows = displayFields.length % cols === 0 ? displayFields.length / cols : displayFields.length / cols + 1;

  const colSpan = gutter / cols;

  const rowNodeList = [];
  for (let i = 0; i < rows; i++) {
    let colNodeList = [];
    for (let j = 0; j < cols; j++) {
      const display = displayFields[i * cols + j];
      colNodeList.push(<Col span={colSpan}><InfoItem label={display.label + "："} value={ data[display.field] } /></Col>)
    }

    rowNodeList.push(
      <Row gutter={gutter}>
        {colNodeList}
      </Row>
    )
  }

  return (
    <>
      { rowNodeList }
    </>
  );
}

export default InfoContent;