import { Col, Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import LoginPanel from "./components/login-panel";

import './index.less';

const Login = () => { 
  return (
    <Layout style={{ height: '100%' }}>
      <Content className="login-content">
        <Row gutter={24} className="login-panel">
          <Col span={12}>
            <Row align="middle" justify="center" style={{height: '100%'}}>
              <span className="welcome-text">生鲜版本管理控制台</span>
            </Row>
          </Col>

          <Col span={12} style={{ backgroundColor: '#ffffff' }}>
            <LoginPanel />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Login;