import React from "react";
import { Select, Form } from "antd";
import http from "@system/utils/http";

export interface FormSelectProps {
  formKey: string;
  options: Array<any> | string;
  labelField?: string;
  valueField?: string;
  placeholder?: string;
  value?: any;
  onChange?: (field: string, value: any) => void;
} 

export default class FormItemSelect extends React.Component<FormSelectProps, any> {

  constructor(props: FormSelectProps) {
    super(props);

    let newOptions = props.options instanceof Array ? props.options : [];

    this.state = {
      options: newOptions
    };
  }

  componentDidMount() {
    const { options } = this.props;
    if (typeof options === 'string') {
      // 请求地址
      http.get(options).then(data => { 
        this.setState({ options: data.data });
      });
    }
  }

  onSelectChange = (option: any) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(this.props.formKey, option);
    }
  }

  render(): React.ReactNode {

    const { labelField, valueField, placeholder, value, formKey, options: _, ...otherProps } = this.props;

    const { options } = this.state;

    const items: any = options.map((option: any) => { 
      const optionValue = valueField ? option[valueField] : option;
      const optionLabel = labelField ? option[labelField] : option;
      return (
        <Select.Option value={optionValue} key={ optionValue }>{ optionLabel }</Select.Option>
      );
    });
    return (
      <Form.Item name={formKey} key={formKey} style={{marginBottom: 12}}>
        <Select placeholder={placeholder} value={value} style={{ width: '100%' }} {...otherProps} onChange={ this.onSelectChange }>
          {items}
        </Select>
      </Form.Item>
      
    );
  }
}