import { Layout } from 'antd';
import HeaderUserInfo from '../header-user-info';
import './index.less';

const {
  Header
} = Layout;

const FrameHeader = () => { 
  return (
    <Header className="site-layout-background">
      <div className='header-left-row'>
        <img src='https://resources.chenyunjie.com/fresh.png' className='logo-image' alt='生鲜' />
        <span className='b2b-header-title-text'>生鲜版1.0</span>
      </div>
      <HeaderUserInfo />
    </Header>
  );
}

export default FrameHeader;