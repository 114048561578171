
import React from 'react'

import { Col, Form, Row, Button } from 'antd';

import FormItemInput from '@system/components/form/form-item-input';
import FormItemSelect from '@system/components/form/form-item-select';
import FormItemDatePicker from '@system/components/form/form-item-date';
import FormItemRangeDatePicker from '@system/components/form/form-item-date-range';

export type CustomerSearchAction = (value: any) => void;

export interface CustomerSearchFormProps {

  onSearch?: CustomerSearchAction;

}

export default class CustomerSearchForm extends React.Component<CustomerSearchFormProps, any> {

  onFinish = (values: any) => {
    const { onSearch } = this.props;
    if (onSearch) {
      onSearch(values);
    }
  };

  render(): React.ReactNode {
    return (
      <>
          
<Form  onFinish={this.onFinish}>
    <Row  gutter={ 24 } style={ { backgroundColor: 'rgba(249,249,249,1)' } }>
        <Col  span={ 6 }>
            <FormItemInput  formKey="name" placeholder="请输入姓名"/>
        </Col>
        <Col  span={ 6 }>
            <FormItemSelect  formKey="sex" placeholder="请选择性别" options={ [{"label":"男","value":1},{"label":"女","value":0}] } labelField="label" valueField="value" />
        </Col>
        <Col  span={ 6 }>
            <FormItemDatePicker  formKey="birthday" placeholder="请选择出生日期" picker="date"/>
        </Col>
        <Col  span={ 6 }>
            <FormItemRangeDatePicker  formKey="daterange" placeholder={ ["开始时间","结束时间"] } picker="date"/>
        </Col>
    </Row>
    <Row  gutter={ 24 } style={ { backgroundColor: 'rgba(249,249,249,1)' } }>
    </Row>
    <Row  gutter={ 24 }>
        <Col  span={ 24 } style={ { textAlign: 'right' } }>
            <Button  type="primary" htmlType="submit">搜索</Button>
            <Button  type="ghost" htmlType="reset" style={ { marginLeft: 10 } }>清空条件</Button>
        </Col>
    </Row>
</Form>
      </>
    );
  }
}
