import { createFromIconfontCN } from '@ant-design/icons';

const Icon = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3610191_lgsh5vfirrp.js'
});

export interface IconProps {
  name: string;
  size?: number;
  color?: string;
}

const WrapperIcon = (props: IconProps) => { 

  const { name, color='#ffffff', size=30, ...other } = props;

  return <Icon type={'icon-' + name} {...other} style={{ fontSize: size, color }} />
}

export default WrapperIcon;