import { List, Popover } from "antd";
import { useState } from "react";
import './index.less';
const HeaderUserInfo = () => { 

  const [v, setV] = useState(false);

  return (
    <Popover content={<UserMenu onLogout={() => setV(false)} />} onVisibleChange={visible => setV(visible)} trigger="click" overlayInnerStyle={{padding: 0}} placement="top" visible={v}>
      <div className="header-user-info">
        admin
      </div>
    </Popover>
    
  );
}

const UserMenu = (props:any) => { 

  const menus = ["退出"];

  const logoutHandler = () => { 
    localStorage.removeItem('token');
    localStorage.removeItem('tokenHeader');
    window.location.href = window.location.protocol + "//" + window.location.host + "/#/login";
    if (props.onLogout) {
      props.onLogout();
    }
  }

  return (
    <div>
      <List 
        style={{padding: 0}}
        dataSource={menus}
        renderItem={item => (
          <List.Item style={{padding: 0}}>
            <div className="b2b-logout-button" onClick={logoutHandler}>{ item }</div>
          </List.Item>
        )}
      />
      
    </div>
  );
}

export default HeaderUserInfo;