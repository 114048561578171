import { message } from "antd";

const HOST = 'http://123.57.75.25:8080'

/**
 * 发起post请求
 * @param url 
 * @param data 
 * @param header 
 */
function post(url: string, data?: any, header?: any): Promise<any> {
  return request(HOST + url, 'POST', data, header);
}

function get(url: string, data?: any, header?: any): Promise<any> {
  return request(HOST + url, 'GET', data, header);
}

function request(url: string, method: string, data?: any, header?: any) {

  const { url: newUrl } = pollyfillUrl(url, method, data);

  return new Promise<any>((resolve, reject) => { 
    fetch(newUrl, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: auth()
      },
      mode: 'cors',
      credentials: 'omit',
      body: method.toLowerCase() === 'post' ? JSON.stringify(data || {}) : null
    })
      .then(data => {
        return data.json()
      })
      .then(response => { 
        if (response.error_msg) {
          message.error(response.error_msg);
          reject();
        } else {
          if (response.status === 401) {
            // 授权登录
            window.location.href = window.location.protocol + "//" + window.location.host + "/#/login";
            reject();
          } else if (response.status === 404) { 
            message.error('系统错误: 404');
            reject();
          } else if (response.status > 401) {
            message.error(response.message);
            reject();
          } else {
            resolve(response);
          }
        }
        
    });
  });
}

function pollyfillUrl(url: string, method: string, data: any) {
  let queryString = '';
  let pathUrl = url;

  if (url.indexOf('?') >= 0) {
    let parts = url.split('?');
    pathUrl = parts[0];
    queryString = parts[1];
  }

  if (method.toLowerCase() === 'get') {
    const bodyQueryString = convert2QueryString(data);
    if (bodyQueryString) {
      if (queryString) {
        queryString = `${queryString}&${bodyQueryString}`;
      } else {
        queryString = `${bodyQueryString}`;
      }
    }
  }

  if (queryString) {
    url = `${pathUrl}?${queryString}`;
  }

  return { url, queryString };
}

function convert2QueryString(data: any, encodeValue?: boolean) {
  if (!data) {
    return '';
  }
  const keys = Object.keys(data);
  return keys.reduce((p, c) => {
    let v = data[c];
    if (encodeValue) {
      v = encodeURIComponent(v);
    }
    if (p) {
      return `${p}&${c}=${v}`
    } else {
      return `${c}=${v}`
    }
    
  }, '');
}

function auth() {
  const token = localStorage.getItem('token');
  const tokenHeader = localStorage.getItem('tokenHeader');

  if (token && tokenHeader) {
    return `${tokenHeader}${token}`;
  }
  return '';
}

const http = { post, get };

export default http;