import React from "react";

import { DatePicker, Form } from "antd";

import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

export interface FormItemDatePickerProps {
  formKey: string;
  value?: any;
  placeholder?: string;
  picker?: any;
}

export default class FormItemDatePicker extends React.Component<FormItemDatePickerProps, any> {

  render(): React.ReactNode {
    const { formKey, value, ...other } = this.props;
    return (
      <Form.Item name={formKey} style={ {marginBottom: 12, width: '100%'} }>
        <DatePicker {...other} value={value} style={{ width: '100%' }} locale={ locale } />
      </Form.Item>
    );
  }
}