import { Divider, Row } from "antd";

import './index.less'

export interface InfoSectionProps {
  // 标题
  title?: string;

  // 内容
  children?: any;
}

const InfoSection = (props: InfoSectionProps) => { 

  return (
    <Row align="middle">
      <div className="info-section-blue-block"></div>
      <span className="info-section-title">{props.title}</span>
      <Divider orientationMargin={10} style={ { margin: '12px 0px'} } />
    </Row>
  );
}

export default InfoSection;