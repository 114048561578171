import { Button, Checkbox, Form, Input, Row, message } from "antd";
import './index.less';
import { useState } from 'react';
import { loginWithPassword } from "src/services/auth";
import event from '@system/utils/event';

const LoginPanel = () => { 

  const [isPrivacyChecked, setIsPrivacyChecked] = useState();

  const onPrivacyCheck = (e:any) => { 
    setIsPrivacyChecked(e.target.checked);
  }

  const loginHandler = (values: any) => { 

    // 跳转新页面
    if (!values.mobile) {
      message.info('请输入手机号码')
      return;
    }

    if (!values.password) {
      message.info('请输入密码')
      return;
    }

    if (!isPrivacyChecked) {
      message.info('请阅读并同意服务协议')
      return;
    }

    const hide = message.loading('正在登录', 0);
    loginWithPassword(values.mobile, values.password).then(response => { 

      if (!response.token) {
        message.error('登录出现错误，请重试');
        hide();
        return;
      }

      hide();

      localStorage.setItem('tokenHeader', response.tokenHeader);
      localStorage.setItem('token', response.token);

      event.emit('menu:refresh');

      setTimeout(() => {
        window.location.replace(window.location.protocol + "//" + window.location.host + "/#/");
      }, 200);
    }).catch(e => { 
      hide();
    });
  }

  return (
    <>
      <div className="login-title">账号登录</div>
            
      <Form className="login-form" onFinish={loginHandler}>
        <Form.Item className="login-form-item" name="mobile">
          <Input placeholder="请输入手机号" allowClear={true} bordered={false} />
        </Form.Item>

        <Form.Item className="login-form-item" name="password">
          <Input.Password placeholder="请输入密码" bordered={ false } />
        </Form.Item>

        <Row justify="space-between">
          <Row align="middle">
            <Checkbox style={{ marginRight: 10 }} onChange={onPrivacyCheck} value={isPrivacyChecked} />
            
            <span className="protol-label-text">阅读并同意</span>
            <Button type="link" style={{ padding: 0 }}>《服务协议》</Button>
          </Row>
          <Button type="link" className="forgot-password-text">忘记密码?</Button>
        </Row>

        <Button type="primary" size="large" style={{ width: '100%', marginTop: 24 }} htmlType="submit">登录</Button>
      </Form>
    </>
  );
}

export default LoginPanel;